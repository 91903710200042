import axios from 'src/utils/axios';
import authService from 'src/services/authService';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';
export const CHANGE_PASSWORD = '@account/change-password';
export const UPDATE_NOTIFICATIONSETTINGS = '@account/update-notificationsettings';
export const GET_NOTIFICATIONSETTINGS = '@account/get-notificationsettings';

export function login(username, password) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const user = await authService.loginWithEmailAndPassword(username, password);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user) {
  return (dispatch) => dispatch({
    type: SILENT_LOGIN,
    payload: {
      user
    }
  });
}

export function logout() {
  return async (dispatch) => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

export function register() {
  return true;
}

export function updateProfile(update) {
  const request = axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/account/profile`, { update });

  return (dispatch) => {
    request.then((response) => dispatch({
      type: UPDATE_PROFILE,
      payload: response.data
    }));
  };
}

export function changePassword(update) {
  return async (dispatch) => {
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/account/changepassword`, { update });
    dispatch({
      type: CHANGE_PASSWORD
    });
  };
}

export function updateNotificationSettings(update) {
  return async (dispatch) => {
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/account/notificationsettings`, { update })
      .then((response) => {
        dispatch({
          type: UPDATE_NOTIFICATIONSETTINGS,
          payload: response.data
        });
      });
  };
}

export function getNotificationSettings() {
  return async (dispatch) => {
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/account/notificationsettings`)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: GET_NOTIFICATIONSETTINGS,
            payload: response.data
          });
        }
      });
  };
}
