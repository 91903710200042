import axios from 'src/utils/axios';

export const NEW_PROPERTY = '@objects/new-property';
export const GET_PROPERTY = '@objects/get-property';
export const DELETE_PROPERTY = '@objects/delete-property';
export const BULK_DELETE_PROPERTIES = '@objects/bulk-delete-properties';
export const UPDATE_PROPERTY_SETTINGS = '@objects/update-property-settings';
export const GET_PROPERTIES_BY_PROPERTYOWNER = '@objects/get-properties-by-propertyowner';

export const NEW_APARTMENT = '@objects/new-apartment';
export const GET_APARTMENT = '@objects/get-apartment';
export const DELETE_APARTMENT = '@objects/delete-apartment';
export const BULK_DELETE_APARTMENTS = '@objects/bulk-delete-apartments';
export const UPDATE_APARTMENT_SETTINGS = '@objects/update-apartment-settings';
export const GET_APARTMENTS_BY_PROPERTY = '@objects/get-apartments-by-property';

export const GET_APARTMENT_SENSORS = '@objects/get-apartment-sensors';

export const NEW_MECHANICAL_ROOM = '@objects/new-mechanical-room';
export const GET_MECHANICAL_ROOM = '@objects/get-mechanical-room';
export const DELETE_MECHANICAL_ROOM = '@objects/delete-mechanical-room';
export const BULK_DELETE_MECHANICAL_ROOMS = '@objects/bulk-delete-mechanical-rooms';
export const UPDATE_MECHANICAL_ROOM_SETTINGS = '@objects/update-mechanical-room-settings';
export const GET_MECHANICAL_ROOMS_BY_PROPERTY = '@objects/get-mechanical-rooms-by-property';

export const UPDATE_APARTMENT_CONNECTION_SETTINGS = '@objects/update-apartment-connection-settings';
export const GET_APARTMENT_CONNECTIONS_BY_MECHANICAL_ROOM = '@objects/get-apartment-connections-by-mechanical-room';

export const NEW_PRICE_LIST = '@objects/new-price-list';
export const UPDATE_PRICE_LIST_SETTINGS = '@objects/update-price-list-settings';
export const GET_PRICE_LISTS_BY_PROPERTY = '@objects/get-price-lists-by-property';
export const DELETE_PRICE_LIST = '@objects/delete-price-list';
export const BULK_DELETE_PRICE_LISTS = '@objects/bulk-delete-price-lists';

export const GET_PROPERTYOWNER = '@objects/get-propertyowner';
export const ADD_PROPERTYOWNER = '@objects/add-propertyowner';
export const UPDATE_PROPERTYOWNER_SETTINGS = '@objects/update-propertyowner-settings';

export const GET_SENSORS_BY_APARTMENT = '@objects/get-sensors-by-apartment';
export const BULK_DELETE_SENSORS = '@objects/bulk-delete-sensors';
export const UPDATE_SENSOR = '@objects/update-sensor';
export const NEW_SENSOR = '@objects/new-sensor';
export const ADD_SENSOR = '@objects/add-sensor'

export const GET_FILE_FORMATS = '@objects/get-file-formats';
// export const GET_USER_AVATAR

/* PROPERTY OWNER */
export function getPropertyOwner(id) {
  return async dispatch => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/propertyowners/${id}`)
      .then(response => {
        if (response.data) {
          dispatch({
            type: GET_PROPERTYOWNER,
            payload: response.data
          });
        }
      });
  };
}

export function addPropertyOwner(propertyOwner) {
  return async dispatch => {
    await axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/api/propertyowners`, propertyOwner)
    // .then(response =>
    //   dispatch({
    //     type: ADD_PROPERTYOWNER,
    //     payload: response.data
    //   })
    // );
  };
}

export function updatePropertyOwnerSettings(update) {
  return async dispatch => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/propertyowners`, {
        update
      })
      .then(response =>
        dispatch({
          type: UPDATE_PROPERTYOWNER_SETTINGS,
          payload: response.data
        })
      );
  };
}
/* END PROPERTY OWNER */

/* PROPERTY */
export function newProperty(propertyOwnerId) {
  return async dispatch => {
    // Defaultvärden för en ny fastighet.
    const newproperty = {
      propertyOwnerId,
      id: 0,
      waterFactor1RoK: 0,
      waterFactor2RoK: 0,
      waterFactor3RoK: 0,
      waterFactor4RoK: 0,
      waterFactor5RoK: 0,
      hotWaterFactor1RoK: 0,
      hotWaterFactor2RoK: 0,
      hotWaterFactor3RoK: 0,
      hotWaterFactor4RoK: 0,
      hotWaterFactor5RoK: 0,
      heatFactor1RoK: 0,
      heatFactor2RoK: 0,
      heatFactor3RoK: 0,
      heatFactor4RoK: 0,
      heatFactor5RoK: 0,
      electricityFactor1RoK: 0,
      electricityFactor2RoK: 0,
      electricityFactor3RoK: 0,
      electricityFactor4RoK: 0,
      electricityFactor5RoK: 0,
      electricityEvFactor1RoK: 0,
      electricityEvFactor2RoK: 0,
      electricityEvFactor3RoK: 0,
      electricityEvFactor4RoK: 0,
      electricityEvFactor5RoK: 0,
      startSummerDateTemp: `${new Date().getFullYear()}-05-01`,
      stopSummerDate: `${new Date().getFullYear()}-09-30`,
      highTemp: 0,
      lowTemp: 0,
      normTemp: 0,
      energyPrice: 0,
      electricityPrice: 0,
      electricityEvPrice: 0,
      waterSensor1: 0,
      waterSensor2: 0,
      especValue: 0,
      comfortValue: 0,
      denotation: '',
      name: '',
      customerCompanyNumber: '',
      areanumber: '',
      deliverypoint: '',
      generatePeriodicData: 0,
      exportfileformatId: 0,
      exportFilenameFormat: '',
      exportColdwater: 0,
      exportHotwater: 0,
      exportElectricity: 0,
      exportElectricityEv: 0,
      exportAirhumidity: 0,
      exportHeatenergy: 0,
      exportAutomatic: 0,
      exportColdwaterDebitcode: '',
      exportHotwaterDebitcode: '',
      exportElectricityDebitcode: '',
      exportElectricityEvDebitcode: '',
      exportAirhumidityDebitcode: '',
      exportHeatenergyDebitcode: '',
      exportPrefixOnPlintB: 0,
      exportSeparateFiles: 0,
      exportIndividualspecOneperflat: 0,
      exportIndividualspecAllinonefile: 0,
      exportIndividualspecCompanyname: '',
      exportIndividualspecAddress1: '',
      exportIndividualspecAddress2: '',
      exportIndividualspecZipcode: '',
      exportIndividualspecCity: '',
      exportIndividualspecPhone: '',
      exportIndividualspecEmail: '',
      dailydataFromMeter: 0
    };

    dispatch({
      type: NEW_PROPERTY,
      payload: { property: newproperty }
    });
  };
}

export function getProperty(id) {
  return async dispatch => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/properties/${id}`)
      .then(response => {
        if (response.data) {
          dispatch({
            type: GET_PROPERTY,
            payload: response.data
          });
        }
      });
  };
}

export function getPropertiesByPropertyOwner(propertyOwnerId) {
  return async dispatch => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/api/properties/bypropertyowner/${propertyOwnerId}`
      )
      .then(response => {
        if (response.data) {
          dispatch({
            type: GET_PROPERTIES_BY_PROPERTYOWNER,
            payload: response.data
          });
        }
      });
  };
}

export function updatePropertySettings(update) {
  return async dispatch => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/properties`, update)
      .then(response => {
        return dispatch({
          type: UPDATE_PROPERTY_SETTINGS,
          payload: response.data
        });
      });
  };
}

export function deleteProperty(id) {
  return async dispatch => {
    await axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/api/properties/${id}`)
      .then(response =>
        dispatch({
          type: DELETE_PROPERTY,
          payload: response.data
        })
      );
  };
}

export function bulkDeleteProperty(update) {
  return async dispatch => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/properties/bulkdelete`, {
        update
      })
      .then(response =>
        dispatch({
          type: BULK_DELETE_PROPERTIES,
          payload: response.data
        })
      );
  };
}
/* END PROPERTY */

/* APARTMENT */

export function getApartment(id) {
  return async dispatch => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/apartments/${id}`)
      .then(response => {
        if (response.data) {
          dispatch({
            type: GET_APARTMENT,
            payload: response.data
          });
        }
      });
  };
}

export function newApartment(propertyId) {
  return async dispatch => {
    const newApartment = {
      id: 0,
      denotation: '',
      houseId: propertyId,
      customerId: 1,
      area: 0.0,
      numberOfLivingRooms: 0,
      numberOfBigBedrooms: 0,
      numberOfSmallBedrooms: 0,
      startSummerDate: `${new Date().getFullYear()}-05-01`,
      stopSummerDate: `${new Date().getFullYear()}-09-30`,
      highTemp: 0.0,
      lowTemp: 0.0,
      normTemp: 0.0,
      resolutionHours: 1.0,
      isRefTemp: 1,
      objectType: 2,
      commissioningDate: null,
      isActivatedForAnalysis: true,
      number: '',
      address: '',
      includeInBillingFiles: true,
      isSubCentral: false,
      mechanicalRoomId: 0,
      sKVNumber: '',
      createdDateTime: '0001-01-01T00:00:00',
      createdBy: 0,
      changedDateTime: '0001-01-01T00:00:00',
      changedBy: 0
    };

    dispatch({
      type: NEW_APARTMENT,
      payload: { apartment: newApartment }
    });
  };
}

export function updateApartmentSettings(update) {
  return async dispatch => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/apartments`, update)
      .then(response => {
        console.log('update response', response);
        return dispatch({
          type: UPDATE_APARTMENT_SETTINGS,
          payload: response.data
        });
      });
  };
}

export function getApartmentsByProperty(propertyId) {
  return async dispatch => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/api/apartments/byproperty/${propertyId}`
      )
      .then(response => {
        if (response.data) {
          dispatch({
            type: GET_APARTMENTS_BY_PROPERTY,
            payload: response.data
          });
        }
      });
  };
}

export function deleteApartment(id) {
  return async dispatch => {
    await axios
      .delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/apartments/${id}`
      )
    // .then(response =>
    //   dispatch({
    //     type: DELETE_APARTMENT,
    //     payload: response.data
    //   })
    // );
  };
}

export function bulkDeleteApartments(apartmentIds) {
  return async dispatch => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/apartments/bulkdelete`,
        apartmentIds
      )
    // .then(response =>
    //   dispatch({
    //     type: BULK_DELETE_APARTMENTS,
    //     payload: response.data
    //   })
    // );
  };
}

/* END APARTMENT */

/* MECHANICAL ROOM */

export function getMechanicalRoom(id) {
  return async dispatch => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/mechanicalrooms/${id}`)
      .then(response => {
        if (response.data) {
          dispatch({
            type: GET_MECHANICAL_ROOM,
            payload: response.data
          });
        }
      });
  };
}

export function getMechanicalRoomsByProperty(propertyId) {
  return async dispatch => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/api/mechanicalrooms/getbyparent/${propertyId}`
      )
      .then(response => {
        if (response.data) {
          dispatch({
            type: GET_MECHANICAL_ROOMS_BY_PROPERTY,
            payload: response.data
          });
        }
      });
  };
}

export function newMechanicalRoom(propertyId) {
  return async dispatch => {
    const newMechanicalRoom = {
      id: 0,
      propertyId,
      propertyDenotation: '',
      denotation: '',
      placement: '',
      serving: '',
      equipment: '',
      apartmentCount: '',
      imd: false,
      surfaceArea: 0,
      comment: '',
      status: '',
      createdDateTime: '0001-01-01T00:00:00',
      createdBy: 0,
      changedDateTime: '0001-01-01T00:00:00',
      changedBy: 0
    };

    dispatch({
      type: NEW_MECHANICAL_ROOM,
      payload: { mechanicalroom: newMechanicalRoom }
    });
  };
}

export function updateMechanicalRoomSettings(update) {
  return async dispatch => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/mechanicalrooms`, update)
      .then(response => {
        return dispatch({
          type: UPDATE_MECHANICAL_ROOM_SETTINGS,
          payload: response.data
        });
      });
  };
}

export function bulkDeleteMechanicalRoom(update) {
  return async dispatch => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/mechanicalrooms/bulkdelete`,
        update
      )
      .then(response =>
        dispatch({
          type: BULK_DELETE_MECHANICAL_ROOMS,
          payload: response.data
        })
      );
  };
}

/* END MECHANICAL ROOM */

/* APARTMENT CONNECTION */

export function getApartmentConnectionsByMechanicalRoomId(mechanicalRoomId) {
  return async dispatch => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/api/mechanicalrooms/${mechanicalRoomId}/apartments`
      )
      .then(response => {
        if (response.data) {
          dispatch({
            type: GET_APARTMENT_CONNECTIONS_BY_MECHANICAL_ROOM,
            payload: response.data
          });
        }
      });
  };
}

export function updateApartmentConnections(mechanicalRoomId, update) {
  return async dispatch => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/mechanicalrooms/${mechanicalRoomId}/apartments`,
        update
      )
      .then(response => {
        return dispatch({
          type: UPDATE_APARTMENT_CONNECTION_SETTINGS,
          payload: response.data
        });
      });
  };
}

/* END APARTMENT CONNECTION */

/* PRICE LIST */

export function getPriceListsByProperty(propertyId) {
  return async dispatch => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/api/pricelists/byproperty/${propertyId}`
      )
      .then(response => {
        if (response.data) {
          dispatch({
            type: GET_PRICE_LISTS_BY_PROPERTY,
            payload: response.data
          });
        }
      });
  };
}

export function addPriceList(priceList) {
  return async dispatch => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/pricelists/`, priceList)
      .then(response => {
        return dispatch({
          type: NEW_PRICE_LIST,
          payload: response.data
        });
      });
  };
}

export function updatePriceList(update) {
  return async dispatch => {
    await axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/api/pricelists/`, update)
      .then(response => {
        return dispatch({
          type: UPDATE_PRICE_LIST_SETTINGS,
          payload: response.data
        });
      });
  };
}

export function bulkDeletePriceLists(priceLists) {
  return async dispatch => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/pricelists/bulkdelete`,
        priceLists
      )
      .then(response =>
        dispatch({
          type: BULK_DELETE_PRICE_LISTS,
          payload: response.data
        })
      );
  };
}

export function newPriceList(houseId) {
  return async dispatch => {
    const newPriceList = {
      id: 0,
      houseId,
      name: '',
      validFrom: '0001-01-01T00:00:00',
      validTo: '0001-01-01T00:00:00',
      coldwaterPrice: 0,
      hotwaterPrice: 0,
      electricityPrice: 0,
      evChargingPrice: 0,
      heatenergyPrice: 0
    };

    dispatch({
      type: NEW_PRICE_LIST,
      payload: { pricelist: newPriceList }
    });
  };
}

/* END PRICE LIST */


/* SENSOR */

export function getSensorsByApartment(id) {
  return async dispatch => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/sensors/byapartment/${id}`)
      .then(response => {
        if (response.data) {
          dispatch({
            type: GET_SENSORS_BY_APARTMENT,
            payload: response.data
          });
        }
      });
  };
}

export function addSensor(sensor) {
  return async dispatch => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/sensors/`, sensor)
      .then(response => {
        return dispatch({
          type: ADD_SENSOR,
          payload: response.data
        });
      });
  };
}

export function updateSensor(sensor) {
  return async dispatch => {
    await axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/api/sensors/`, sensor)
      .then(response => {
        return dispatch({
          type: UPDATE_SENSOR,
          payload: response.data
        });
      });
  };
}

export function newSensor(apartmentId) {
  return async dispatch => {
    const newSensor = {
      apartmentId,
      radioId: 0,
      tempType: 0,
      pulsesPlintA: 0,
      pulsesPlintB: 0,
      typePlintA: 0,
      typePlintB: 0,
      typeStringPlintA: 0,
      typeStringPlintB: 0,
      roomSize: 0,
      lastMessageDateTime: '0001-01-01T00:00:00',
      sabotage: false,
      batteryStatus: 0,
      masterRadioId: 0,
      idPrefix1: '',
      idPrefix2: '',
      debitTypePlintA: 0,
      debitTypePlintB: 0,
    };

    dispatch({
      type: NEW_SENSOR,
      payload: { sensor: newSensor }
    })
  };
}

/* END SENSOR */

/* MISC */

export function getFileFormats() {
  return async dispatch => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/export/availableparsers`)
      .then(response => {
        return dispatch({
          type: GET_FILE_FORMATS,
          payload: response.data
        });
      });
  }
}

/* END MISC */