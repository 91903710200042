/* eslint-disable react/no-array-index-key */
import React, {
  lazy,
  Suspense,
  Fragment
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import AdminGuard from './components/AdminGuard';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/app" />
  },
  {
    exact: true,
    path: '/403',
    component: lazy(() => import('src/views/pages/Error403View'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/dashboard" />
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/dashboard',
        component: lazy(() => import('src/views/dashboards'))
      },
      {
        exact: true,
        path: '/app/users',
        component: lazy(() => import('src/views/objects/UserListView'))
      },
      {
        exact: true,
        path: '/app/import',
        component: lazy(() => import('src/views/objects/ImportView'))
      },
      {
        exact: true,
        path: '/app/log',
        component: lazy(() => import('src/views/objects/LogView'))
      },
      {
        exact: true,
        path: '/app/map',
        component: lazy(() => import('src/views/objects/MapView'))
      },
      {
        exact: true,
        path: '/app/propertyowner/:id/createproperty',
        component: lazy(() => import('src/views/objects/PropertyCreateView'))
      },
      {
        exact: true,
        path: '/app/property/:id/createapartment',
        component: lazy(() => import('src/views/objects/ApartmentCreateView'))
      },
      {
        exact: true,
        path: '/app/property/:id/createmechanicalroom',
        component: lazy(() => import('src/views/objects/MechanicalRoomCreateView'))
      },
      {
        exact: true,
        path: '/app/property/:id',
        component: lazy(() => import('src/views/objects/PropertyView'))
      },
      {
        exact: true,
        path: '/app/property/:id/:tab',
        component: lazy(() => import('src/views/objects/PropertyView'))
      },
      {
        exact: true,
        path: '/app/apartment/:id',
        component: lazy(() => import('src/views/objects/ApartmentView'))
      },
      {
        exact: true,
        path: '/app/apartment/:id/:tab',
        component: lazy(() => import('src/views/objects/ApartmentView'))
      },
      {
        exact: true,
        path: '/app/mechanicalroom/:id',
        component: lazy(() => import('src/views/objects/MechanicalRoomView'))
      },
      {
        exact: true,
        path: '/app/mechanicalroom/:id/:tab',
        component: lazy(() => import('src/views/objects/MechanicalRoomView'))
      },
      {
        exact: true,
        path: '/app/propertyowner/:id/',
        component: lazy(() => import('src/views/objects/PropertyOwnerSummaryView'))
      },
      {
        exact: true,
        path: '/app/propertyowner/:id/:tab',
        component: lazy(() => import('src/views/objects/PropertyOwnerSummaryView'))
      },
      {
        exact: true,
        path: '/app/createpropertyowner',
        routes: [
          {
            exact: true,
            path: '/app/createpropertyowner',
            guard: AdminGuard,
            component: lazy(() => import('src/views/objects/PropertyOwnerCreateView'))
          }
        ]
      },
      {
        exact: true,
        path: '/app/debit',
        routes: [
          {
            exact: true,
            path: '/app/debit',
            guard: AdminGuard,
            component: lazy(() => import('src/views/objects/DebitView'))
          }
        ]
      },
      {
        exact: true,
        path: '/app/statuses',
        routes: [
          {
            exact: true,
            path: '/app/statuses',
            guard: AdminGuard,
            component: lazy(() => import('src/views/objects/StatusView'))
          }
        ]
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    exact: true,
    path: '/bkab.se',
    component: () => { window.location = 'http://bkab.se'; return null; }
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
