import axios from 'src/utils/axios';
import { Buffer } from 'buffer';

class __AvatarImage {
  static avatarImageData = {};
  static avatarImageBase64 = {};
  static avatarImageContentType = {};
}

export async function getBase64(id) {
  if (!__AvatarImage.avatarImageBase64[id]) {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const url = `${baseUrl}/api/user/getavatar/${id}`;
    const { data, headers } = await axios.get(url, {
      responseType: 'arraybuffer'
    });
    __AvatarImage.avatarImageBase64[id] = Buffer.from(data, 'binary').toString('base64');
    __AvatarImage.avatarImageContentType[id] = headers['content-type'];
  }

  return __AvatarImage.avatarImageBase64[id] ? `data:${__AvatarImage.avatarImageContentType[id]};base64,${__AvatarImage.avatarImageBase64[id]}` : '';
}

export async function getBlob(id) {
  if (!__AvatarImage.avatarImageData[id]) {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const url = `${baseUrl}/api/user/getavatar/${id}`;
    const { data } = await axios.get(url, {
      responseType: 'blob'
    });
    __AvatarImage.avatarImageData[id] = data;
  }
  return __AvatarImage.avatarImageData[id];
}

export function clearImage(id) {
  __AvatarImage.avatarImageBase64[id] = undefined;
  __AvatarImage.avatarImageContentType[id] = undefined;
  __AvatarImage.avatarImageData[id] = undefined;
}