
// import * as icons from "@fortawesome/free-solid-svg-icons";
import * as icons from "@fortawesome/pro-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(
  icons.faHome,
  icons.faHotel,
  icons.faBriefcase,
  icons.faBuilding,
  icons.faCity,
  icons.faChartLine,
  icons.faUsers,
  icons.faFileUpload,
  icons.faFileImport,
  icons.faPlusCircle,
  icons.faBolt,
  icons.faChargingStation,
  icons.faThermometerHalf,
  icons.faChartPie,
  icons.faInfoCircle,
  icons.faMoneyBill,
  icons.faHouseUser,
  icons.faPlug,
  icons.faNetworkWired,
  icons.faHeartbeat,
  icons.faCog,
  icons.faRssSquare,
  icons.faSyncAlt,
  icons.faThList,
  icons.faCoins,
  icons.faChevronLeft,
  icons.faChevronRight,
  icons.faCaretSquareRight,
  icons.faLock,
  icons.faUnlock,
  icons.faPen,
  icons.faChevronDown,
  icons.faPlusSquare,
  icons.faTriangleExclamation,
  icons.faExclamationTriangle,
  icons.faAsterisk,
  icons.faWrench,
  icons.faBullseye,
  icons.faPodcast,
  icons.faSensor,
  icons.faCarCircleBolt,
  icons.faNfcSignal,
  icons.faMeter,
  icons.faCircleDollar,
  icons.faLocationDot,
  icons.faLocationDotSlash,
  icons.faLocationPen,
  icons.faLocationCheck,
  icons.faLocationXmark,
  icons.faTrashXmark,
  icons.faArrowUpRightAndArrowDownLeftFromCenter,
  icons.faArrowDownLeftAndArrowUpRightToCenter,
  icons.faExpandWide,
  icons.faCompressWide,
  icons.faExclamation,
  icons.faCircleExclamation,
  icons.faTemperatureFull,
  icons.faMapLocationDot,
  icons.faMonitorWaveform,
  icons.faGears,
  icons.faArrowRight,
  icons.faDroplet,
  icons.faDropletPercent,
  icons.faFireFlameCurved,
  icons.faCarBolt
);