/* eslint-disable import/prefer-default-export */
export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const COLORS = {
  COLDWATER: 'royalblue',
  AIRHUMIDITY: 'lightseagreen',
  ELECTRICITY: 'gold',
  EVCHARGING: 'gold',
  HEATENERGY: 'darkorange',
  HOTWATER: 'orangered',
  KAS: 'limegreen'
};

export const OBJECT_TYPES = {
  ALL: 0,
  PROPERTYOWNER: 1,
  PROPERTY: 2,
  APARTMENT: 3,
  SENSOR: 4,
  MECHANICALROOM: 5,
  CHARGINGPOINT: 7
};

export const RESOLUTION = {
  HOURLY: 0,
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
  YEARLY: 4
};

export const USER_ROLES = {
  SUPERADMIN: 2,
  PROPERTYOWNERADMIN: 1,
  ENDUSER: 0
};

export const SENSOR_TYPES = {
  NONE: 0,
  COLDWATER: 1,
  HOTWATER: 2,
  ELECTRICITY: 3,
  AIRHUMIDITY: 4,
  HEATENERGY: 5,
  EVCHARGING: 6,
  EVCHARGINGSESSION: 7
};

export const APARTMENT_OBJECT_TYPE = {
  KAS: 1,
  REFTEMP: 2,
  HEATENERGY: 3
};

export const APPLICATION = {
  PORTAL: 1,
  DATABASE: 2,
  BACKGROUND_WORKER: 3,
  BINARY_PARSER: 4
};

export const SERVICE_STATUS = {
  UNKNOWN: 0,
  OK: 1,
  RUNNING: 2,
  WARNING: 3,
  CRITICAL: 4,
};