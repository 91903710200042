/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_PROPERTYOWNER,
  UPDATE_PROPERTYOWNER_SETTINGS,
  NEW_PROPERTY,
  GET_PROPERTY,
  GET_PROPERTIES_BY_PROPERTYOWNER,
  DELETE_PROPERTY,
  BULK_DELETE_PROPERTIES,
  UPDATE_PROPERTY_SETTINGS,
  NEW_APARTMENT,
  GET_APARTMENT,
  DELETE_APARTMENT,
  BULK_DELETE_APARTMENTS,
  UPDATE_APARTMENT_SETTINGS,
  GET_MECHANICAL_ROOM,
  GET_MECHANICAL_ROOMS_BY_PROPERTY,
  NEW_MECHANICAL_ROOM,
  UPDATE_MECHANICAL_ROOM_SETTINGS,
  DELETE_MECHANICAL_ROOM,
  BULK_DELETE_MECHANICAL_ROOMS,
  UPDATE_APARTMENT_CONNECTION_SETTINGS,
  GET_APARTMENT_CONNECTIONS_BY_MECHANICAL_ROOM,
  GET_APARTMENTS_BY_PROPERTY,
  GET_PRICE_LISTS_BY_PROPERTY,
  NEW_PRICE_LIST,
  UPDATE_PRICE_LIST_SETTINGS,
  GET_SENSORS_BY_APARTMENT,
  GET_FILE_FORMATS,
  ADD_PROPERTYOWNER
} from 'src/actions/objectActions';

const initialState = {
  propertyowner: null,
  property: null,
  properties: null,
  apartment: null,
  mechanicalroom: null,
  mechanicalrooms: null,
  connections: null,
  apartments: null,
  pricelists: null,
  pricelist: null,
  apartmentSensors: null,
  fileFormats: null
};

const objectReducer = (state = initialState, action) => {
  switch (action.type) {
    //Property
    case NEW_PROPERTY: {
      const { property } = action.payload;

      return produce(state, draft => {
        draft.property = property;
      });
    }

    case GET_PROPERTY: {
      const property = action.payload;

      return produce(state, draft => {
        draft.property = property;
      });
    }

    case GET_PROPERTIES_BY_PROPERTYOWNER: {
      const properties = action.payload;

      return produce(state, draft => {
        draft.properties = properties;
      });
    }

    case UPDATE_PROPERTY_SETTINGS: {
      const { property } = action.payload;

      return produce(state, draft => {
        draft.property = property;
      });
    }

    case DELETE_PROPERTY: {
      return produce(state, draft => {
        draft.property = null;
      });
    }

    case BULK_DELETE_PROPERTIES: {
      return produce(state, () => { });
    }

    //Property owner
    case GET_PROPERTYOWNER: {
      const { propertyowner } = action.payload;

      return produce(state, draft => {
        draft.propertyowner = propertyowner;
      });
    }

    case ADD_PROPERTYOWNER: {
      const { propertyowner } = action.payload;

      return produce(state, draft => {
        draft.propertyowner = propertyowner;
      });
    }

    case UPDATE_PROPERTYOWNER_SETTINGS: {
      const { propertyowner } = action.payload;

      return produce(state, draft => {
        draft.propertyowner = propertyowner;
      });
    }

    //Apartment
    case GET_APARTMENT: {
      const { apartment } = action.payload;

      return produce(state, draft => {
        draft.apartment = apartment;
      });
    }

    case NEW_APARTMENT: {
      const { apartment } = action.payload;

      return produce(state, draft => {
        draft.apartment = apartment;
      });
    }

    case UPDATE_APARTMENT_SETTINGS: {
      const { apartment } = action.payload;

      return produce(state, draft => {
        draft.apartment = apartment;
      });
    }

    case GET_APARTMENTS_BY_PROPERTY: {
      return produce(state, draft => {
        draft.apartments = action.payload;
      });
    }

    //Mechanical room
    case GET_MECHANICAL_ROOM: {
      const { mechanicalroom } = action.payload;

      return produce(state, draft => {
        draft.mechanicalroom = mechanicalroom;
      });
    }

    case GET_MECHANICAL_ROOMS_BY_PROPERTY: {
      return produce(state, draft => {
        draft.mechanicalrooms = action.payload;
      });
    }

    case NEW_MECHANICAL_ROOM: {
      const { mechanicalroom } = action.payload;

      return produce(state, draft => {
        draft.mechanicalroom = mechanicalroom;
      });
    }

    case UPDATE_MECHANICAL_ROOM_SETTINGS: {
      const { mechanicalroom } = action.payload;

      return produce(state, draft => {
        draft.mechanicalroom = mechanicalroom;
      });
    }

    //Apartment connection
    case GET_APARTMENT_CONNECTIONS_BY_MECHANICAL_ROOM: {
      return produce(state, draft => {
        draft.connections = action.payload;
      });
    }

    case UPDATE_APARTMENT_CONNECTION_SETTINGS: {
      return produce(state, draft => {
        draft.connections = action.payload;
      });
    }

    //Price list
    case GET_PRICE_LISTS_BY_PROPERTY: {
      return produce(state, draft => {
        draft.pricelists = action.payload;
      });
    }

    case NEW_PRICE_LIST: {
      return produce(state, draft => {
        draft.pricelist = action.payload;
      });
    }

    case UPDATE_PRICE_LIST_SETTINGS: {
      return produce(state, draft => {
        draft.pricelist = action.payload;
      });
    }

    //Sensor
    case GET_SENSORS_BY_APARTMENT: {
      return produce(state, draft => {
        draft.apartmentSensors = action.payload;
      });
    }

    //Misc
    case GET_FILE_FORMATS: {
      return produce(state, draft => {
        draft.fileFormats = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default objectReducer;
